import React from "react";

const Specialisation = (props) => {
  return (
    <section className={`section${props.altClass ? ` ` + props.altClass : ""}`}>
      <div className="container">
        <div className="specialisation">
          <div
            className={`grid${
              props.gridClass ? ` ` + props.gridClass : ""
            } grid--align-center grid--justify-center`}
          >
            <div className="grid__item medium--one-whole">
              <div className="specialisation__header">
                <h2 className="specialisation__title">{props.title}</h2>
                <p className="specialisation__desc">{props.desc}</p>
              </div>
            </div>
            {props.image ? (
              <div className="grid__item medium--one-third">
                <div className="visual image-round">
                  <img src={props.image} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}
            {props.specialisation2 ? props.specialisation2 : ""}
            {props.specialisation3 ? props.specialisation3 : ""}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Specialisation;
