import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Specialisation from "../components/Specialisation";
import HeroSmall from "../components/HeroSmall";
import osteo1 from "../../static/osteopathie1.jpg";
import osteo2 from "../../static/simona1.jpg";
import osteo3 from "../../static/sideroll.jpg";
import osteo4 from "../../static/rug.jpg";
import osteo5 from "../../static/nek.jpg";
import osteo6 from "../../static/sacrum.jpg";
import osteo7 from "../../static/simona2.jpg";
import osteo8 from "../../static/simona3.jpg";
import osteo9 from "../../static/zwanger-buik.jpg";
import osteo10 from "../../static/zwanger-ruglig.jpg";
import osteo11 from "../../static/zwanger-zijlig.jpg";

const OsteopathiePage = ({ location, data }) => {
  const url = location.pathname ? location.pathname : "";

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Osteopathie – Kine-Osteo Hagers</title>
        <meta
          name="description"
          content="We werken ook samen met osteopate Lien Hagers"
        />
      </Helmet>
      <Layout routeUrl={url}>
        <section className="section section--no-mt section--no-mb">
          <HeroSmall
            title="Osteopathie"
            hero={osteo1}
            heroDetails={
              <p className="hero__desc">
                <strong>LIEN HAGERS OSTEOPAAT D.O.</strong>
                <br />
                Koning Albert-I-laan 43 A11
                <br />
                8370 Blankenberge
                <br />
                GSM: 0484 16 06 66
              </p>
            }
          />
        </section>
        <Specialisation
          title="Over mij"
          image={osteo2}
          specialisation2={
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo3} alt="" />
              </div>
            </div>
          }
          specialisation3={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo1} alt="" />
              </div>
            </div>,
          ]}
          desc={
            <div>
              <p>
                Na mijn studies 'Master in de Bewegingswetenschappen' aan de
                universiteit van Gent volgde ik de opleiding osteopathie aan
                'The International Academy of Osteopathy'. In 2010 behaalde ik
                mijn diploma <strong>osteopaat D.O.</strong> uitgereikt door
                'The University of Wales'.
              </p>
              <p>
                Met de tweejarige postgraduate opleiding{" "}
                <strong>kinderosteopathie</strong> in Nederland specialiseerde
                ik mij - naast het behandelen van volwassenen - tevens in de
                behandeling van{" "}
                <strong>baby's, kinderen en zwangere vrouwen.</strong>
              </p>
              <p>
                Ik ben lid van het <strong>B.V.B.O.</strong> (Beroepsvereniging
                van de Belgische Osteopaten) en volg op regelmatige basis
                postgraduate cursussen in de osteopathie.
              </p>
            </div>
          }
        />
        <Specialisation
          image={osteo4}
          title="Osteopathie"
          altClass="section--light"
          specialisation2={
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo5} alt="" />
              </div>
            </div>
          }
          specialisation3={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo6} alt="" />
              </div>
            </div>,
          ]}
          desc={
            <div>
              <p>
                Osteopathie is een <strong>manuele</strong> onderzoeks- en
                behandelwijze waarbij gezocht wordt naar lichamelijke{" "}
                <strong>oorzaak-gevolg ketens</strong> die aan de oorsprong
                liggen van diverse klachten. Het lichaam wordt als één geheel
                beschouwd waarin alles met elkaar verbonden is en invloed op
                elkaar heeft. Een osteopaat gaat op zoek naar
                <strong> bewegingsbeperkingen</strong> van weefsels en
                gewrichten. Wanneer de mobiliteit hersteld wordt, zal het{" "}
                <strong>zelfgenezend vermogen </strong>
                gestimuleerd worden en is het lichaam terug in staat zichzelf te
                herstellen. De osteopaat geneest u niet, maar geeft wel het
                lichaam de juiste voorzet zodat het weer zelf in staat is om
                verder orde op zaken te stellen.
              </p>
              <p>
                Osteopathie is een veilige behandelmethode gebaseerd op
                principes uit de anatomie, neurologie, fysiologie en
                embryologie. De technieken worden op een{" "}
                <strong>zachte en veilige </strong>
                manier afgestemd van persoon tot persoon. De behandelingen zijn
                gericht op de decompressie en het bewegingsherstel of
                activatie van zowel perifere als centrale structuren om op deze
                manier meer vitaliteit en expressiemogelijkheden te bieden aan
                bloedvaten en zenuwen. Op die manier wordt ‘ruimte gecreëerd’,
                gedynamiseerd en worden oude letselpatronen opgeruimd.
              </p>
              <p>
                Osteopathie behoort tot de complementaire geneeswijzen en wordt
                door de ziekenfondsen erkend en gedeeltelijk vergoed. Met
                aanvullende verzekeringen zoals MEDICALIA kan je tot 75% van het
                remgeld terugtrekken. Informeer hiervoor bij uw ziekenfonds. Er
                is geen doorverwijzing van een arts nodig voor een bezoek aan de
                osteopaat. Osteopathie is een aanvulling op de klassieke
                geneeskunde, geen vervanging ervan. Daarom is een goede
                samenwerking met artsen en andere zorgverstrekkers belangrijk
                voor het welzijn van de patiënt.
              </p>
            </div>
          }
        />
        <Specialisation
          image={osteo7}
          title="Enkele indicaties bij volwassenen"
          specialisation2={
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo1} alt="" />
              </div>
            </div>
          }
          specialisation3={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo8} alt="" />
              </div>
            </div>,
          ]}
          desc={
            <div>
              <ul className="list list--border-bottom">
                <li>
                  (Chronische) rugpijn: ischialgie, lumbago, hernia's,
                  blokkades, spierstijfheid, …
                </li>
                <li>Uitstralende pijn in armen of benen, piriformissyndroom</li>
                <li>Slapende handen of voeten</li>
                <li>Zware, vermoeide benen</li>
                <li>
                  Nekpijn: torticollis, blokkades, trapeziussyndroom, whiplash,
                  …
                </li>
                <li>
                  Schouderklachten: impingement, frozen shoulder, chronische
                  ontstekingen, …
                </li>
                <li>
                  Chronische peesontstekingen: tenniselleboog,
                  achillespeesontsteking, …
                </li>
                <li>
                  Spier- en gewrichtspijnen: artrose, artritis, reuma,
                  chronische ontstekingen, bursitis, iliotibiaal
                  frictiesyndroom, …
                </li>
                <li>Kaakgewrichtsklachten, aangezichtspijnen</li>
                <li>Hoofdpijn: spanningshoofdpijn, migraine, …</li>
                <li>
                  Neus-keel-oor: chronische sinusitis, oorsuizen, cervicogene
                  oorpijn (pijn- of drukgevoel in en rond het oor), …
                </li>
                <li>Duizeligheid of evenwichtsstoornissen</li>
                <li>
                  Spijsverteringsklachten: (chronische) constipatie of diarree,
                  spastische darmen, een opgeblazen gevoel, krampen, zure
                  oprispingen, maagpijn, misselijkheid, …
                </li>
                <li>Buikpijn</li>
                <li>
                  Gynaecologische problemen, zwangerschapswens, chronische
                  blaasontstekingen, incontinentieproblemen
                </li>
                <li>Bekkeninstabiliteit </li>
                <li>
                  Verminderde vitaliteit: slaapproblemen, (chronische)
                  vermoeidheid, …
                </li>
                <li>
                  Stressgerelateerde klachten: druk op het borstbeen, algemene
                  overprikkeling, ademhalingsproblemen, (chronische)
                  hyperventilatie, burn-out, …
                </li>
                <li>Concentratiestoornissen</li>
                <li>
                  Sportletsels: tendinitis, spierkrampen, spierscheuren,
                  overbelastingskwetsuren
                </li>
                <li>
                  Posttraumatische klachten: controle na een zware val,
                  verstuiking of aanrijding, staartbeenproblematiek, …
                </li>
                <li>
                  Postoperatieve klachten: littekens, klachten na heup- of
                  knieprothese of na artrodese lumbaal of cervicaal, …
                </li>
                <li>Oude terugkerende letsels</li>
                <li>
                  Preventieve controle om een klacht in wording in de kiem te
                  smoren
                </li>
              </ul>
              <p>
                Het is echter onmogelijk om een volledige lijst van
                osteopatische indicaties weer te geven. Indien u een vraag hebt
                over uw klacht en of die mogelijks kan verholpen worden met
                osteopathie, aarzel dan zeker niet om contact op te nemen.
              </p>
              <p>
                Indien ernstige pathologie, infectie of acute ontsteking wordt
                vermoed, zal de patiënt naar de huisarts of specialist
                doorverwezen worden. Dit zijn immers geen indicaties voor
                osteopathie.
              </p>
            </div>
          }
        />
        <Specialisation
          title="Behandeling van baby's en kinderen"
          altClass="section--light"
          desc={
            <div>
              <p>
                De geboorte is voor de baby een traumatische en stressvolle
                gebeurtenis. De baby moet zich namelijk vanuit de baarmoeder
                doorheen het benige bekken wringen en draaien. Bij die passage
                door het bekken werken er enorme compressiekrachten op hem in.
                Het hoofdje van de baby kan zich goed aan deze drukkrachten
                aanpassen. De vervormingen verdwijnen dan ook meestal kort na de
                geboorte. Soms verloopt de bevalling echter anders dan verwacht
                (een lange of juist erg snelle bevalling, (spoed)keizersnede,
                gebruik van zuignap of verlostang,…), waardoor de baby klachten
                kan ontwikkelen. Onopgeloste spanningen en mechanische
                verstoringen liggen vaak aan de basis van problemen in de
                verdere ontwikkeling van het kind. Daarom is het belangrijk dat
                baby's <strong>vroegtijdig</strong> behandeld worden zodat de
                groei optimaal kan verlopen.
              </p>
            </div>
          }
        />
        <Specialisation
          title="Met welke klachten kan ik met mijn baby bij de osteopaat terecht?"
          desc={
            <div>
              <ul className="list list--border-bottom">
                <li>Reflux</li>
                <li>Darmkrampjes, verteringsproblemen</li>
                <li>Slik- of zuigstoornissen</li>
                <li>
                  Voorkeurshouding (de baby kijkt steeds naar dezelfde kant)
                </li>
                <li>
                  Vormsafwijking of afvlakking van het hoofdje (plagiocephalie)
                </li>
                <li>Torticollis of scheefstand van het hoofdje</li>
                <li>
                  Neus-keel-oor: chronische luchtwegproblemen, slijmpjes,
                  oorontstekingen, …
                </li>
                <li>Huilbaby's, onrustige baby’s, excessief huilen</li>
                <li>Slaapproblemen, dag-/nachtritme stoornissen</li>
                <li>Zich steeds overstrekken</li>
                <li>
                  Stoornissen in de motorische ontwikkeling (bv. de kruipfase
                  overslaan, …)
                </li>
                <li>…</li>
              </ul>
              <p>
                Een kind is geen kleine volwassene omdat een kind volop in
                ontwikkeling is, zowel mentaal als fysiek. Elke
                ontwikkelingsfase kent zijn eigen anatomische en fysiologische
                aanpassingen. Ieder kind vergt dan ook een{" "}
                <strong>unieke benadering</strong>. Tijdens de behandeling
                worden zachte manuele technieken gebruikt, die het kind ervaart
                als aangenaam en verlichtend. Met speeltjes en boekjes wordt de
                behandeling zo aangenaam mogelijk gemaakt.
              </p>
            </div>
          }
        />
        <Specialisation
          title="Met welke klachten kan ik met mijn kind bij de osteopaat terecht?"
          altClass="section--light"
          desc={
            <div>
              <ul className="list list--border-bottom">
                <li>Rug-, nek- en andere gewrichtsklachten</li>
                <li>Scoliose</li>
                <li>Hoofdpijn</li>
                <li>Buikpijn, verteringsproblemen</li>
                <li>
                  Neus-keel-oor: chronische luchtwegproblemen, slijmen,
                  recidiverende oorontstekingen
                </li>
                <li>Astma</li>
                <li>Bedplassen</li>
                <li>Kaakgewrichtsproblemen, ondersteuning bij orthodontie</li>
                <li>
                  Leer-, concentratie- en gedragsproblemen, ondersteuning bij
                  logopedie
                </li>
                <li>
                  Motorische achterstand, trage ontwikkeling van grove of fijne
                  motoriek
                </li>
                <li>Slaapproblemen</li>
                <li>Groeipijnen</li>
              </ul>
              <p>
                Een kind is geen kleine volwassene omdat een kind volop in
                ontwikkeling is, zowel mentaal als fysiek. Elke
                ontwikkelingsfase kent zijn eigen anatomische en fysiologische
                aanpassingen. Ieder kind vergt dan ook een unieke benadering.
                Tijdens de behandeling worden zachte manuele technieken
                gebruikt, die het kind ervaart als aangenaam en verlichtend. Met
                speeltjes en boekjes wordt de behandeling zo aangenaam mogelijk
                gemaakt.
              </p>
            </div>
          }
        />
        <Specialisation
          title="Zwangere vrouwen"
          image={osteo9}
          specialisation2={
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo10} alt="" />
              </div>
            </div>
          }
          specialisation3={[
            <div className="grid__item medium--one-third">
              <div className="visual image-round">
                <img src={osteo11} alt="" />
              </div>
            </div>,
          ]}
          desc={
            <div>
              <p>
                Ook bij zwangerschapsklachten kan een osteopaat helpen. Voor een
                goede zwangerschap is een goede doorbloeding, beweeglijkheid en
                balans van het hele bekkengebied van groot belang.
              </p>
              <p>
                Bij de behandeling van de zwangere vrouw staan de wervelkolom,
                het bekken, het middenrif en de glijvlakken tussen de organen
                centraal. Op die manier wordt er terug een{" "}
                <strong>evenwicht</strong> in het lichaam gebracht, zodat niet
                alleen het welzijn van de vrouw verbetert, maar er ook meer
                ruimte voor de baby gecreëerd wordt. Dit vergemakkelijkt in veel
                gevallen de bevalling. Een osteopatische behandeling houdt geen
                enkel gevaar in voor het ongeboren kind.
              </p>
              <ul className="list list--border-top">
                <p>
                  <strong>Indicaties:</strong>
                </p>
                <li>Ongemakken t.h.v. rug, bekken en staartbeen</li>
                <li>Zware en vermoeide benen, beenkrampen</li>
                <li>Liespijn</li>
                <li>Bekkeninstabiliteit/bekkengordelpijn</li>
                <li>Blaasproblemen</li>
                <li>Hoofdpijn</li>
                <li>Kortademigheid</li>
                <li>Pre- en postnatale opvolging</li>
                <li>
                  Slapende of tintelende handen en voeten, carpaal
                  tunnelsyndroom
                </li>
                <li>Zure oprispingen</li>
                <li>
                  Klachten na de bevalling: na keizersnede, lactatieproblemen, …
                </li>
              </ul>
            </div>
          }
        />
      </Layout>
    </div>
  );
};

export default OsteopathiePage;
